.PrivateTabIndicator-colorSecondary-150 {
  background-color: #00bfff !important;
}

.PrivateTabIndicator-colorSecondary-190 {
  background-color: #00bfff !important;
}

.MuiTabs-indicator {
  background-color: #00bfff !important;
}